.app-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #28A8E0;
}

.header-h {
  height: 142px;
}

.box-bottom {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: url('/public/static/bg-bottom.png') left bottom no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #0054A5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  & img {
    height: 52px;
    width: auto;
  }

  & .desc-right {
    text-align: left;
    color: white;
    padding-left: 32px;

    & .store-name {
      font-size: 24px;
      font-weight: 600;
      line-height: 6px;
    }

    & .store-address {
      font-size: 12px;
    }

    & .store-date {
      font-size: 12px;
    }
  }
}

.gift-left-container {
  position: absolute;
  left: 0;
  height: 48%;
  width: auto;
  z-index: 10;
  top: 30%;
}

.gift-right-container {
  position: absolute;
  right: 0;
  height: 48%;
  width: auto;
  z-index: 10;
  top: -7%;
}

.box-wheel {
  padding-top: 78px;
  padding-bottom: 38px;
  background-color: #004384;
  width: 100%;
  max-width: 704px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 24px;

  & .box-header {
    border-radius: 54px;
    background: linear-gradient(180deg, #F6B914 0%, #E47E32 100%);
    box-shadow: 0px 96.188px 54px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.24);
    color: #FFF;
    text-align: center;
    text-shadow: 0 2.5px 0 rgba(79, 79, 79, .6), 0 4.5px 18px rgba(0, 0, 0, .25);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    max-width: 400px;
    width: 100%;
    padding: 26px 24px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    margin-top: -36px;
  }
}

.box-container {
  position: relative;
  overflow: hidden;
  max-width: 950px;
  max-height: 754px;
  padding: 6rem 0;
  width: 100%;
  /* height: 80vh; */
  border-radius: 24px;
  border: 4px solid #D1A54D;
  background: url('/public/static/bg-box.png') 50% / cover no-repeat;
  background-size: 110%;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-ellipse {
  border-radius: 1075px;
  border: 500px dashed #D3EAFF;
  opacity: 0.2;
  mix-blend-mode: screen;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-image-source: radial-gradient(50% 50% at 50% 50%, #D3EAFF 0%, rgba(0, 118, 169, 0) 100%);
}


.box-bg {
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  background: url('/public/static/bg-box.png') 50% / cover no-repeat;
}

.modal-input {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000070;
  display: flex;
  align-items: center;
  justify-content: center;

  & .input-container {
    background: white;
    border-radius: 12px;
    padding: 12px 18px;
    max-width: 375px;
    width: 100%;

    & .title {
      color: #0F172A;
      font-size: 18px;
      font-weight: 600;
    }

    & .btn-accept {
      background-color: #0054A5;
      color: white;
      width: 100%;
      padding: 16px 0;
      outline: none;
      border: none;
      border-radius: 16px;
      cursor: pointer;
      margin-top: 16px;
    }
  }
}

.lucky-drawer-container {
  position: relative;
  .number-item {
    color: white
  }

  & .name-phone {
    font-size: 24px;
  }
  p {
    margin: 0;
  }
}

.gift-container {
  padding: 48px 102px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & .gift-left-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    width: auto;
    max-width: 500px;

    & .name {
      font-size: 36px;
      font-weight: 700;
      line-height: 0.2em;
    }

    & .remaining-prizes {
      margin-top: -2px;
      color: #004384;
      font-size: 20px;
      font-weight: 700;
      background-color: white;
      padding: 2px 12px;
      border-radius: 24px;
    }

    & .price {
      font-size: 22px;
      font-weight: 400;
      line-height: 1em;
      margin-top: 0 !important;
    }
  }

  & .gift-right-item {
    margin-left: 24px;
    padding: 12px;
    border-radius: 12px;
    background: white;

    & img {
      width: auto;
      height: 120px;
      border-radius: 12px;
    }
  }
}

.lucky-btn-container {
  color: #FFF;
  text-align: center;
  text-shadow: 0 2.5px 0 rgba(79, 79, 79, .6), 0 4.5px 18px rgba(0, 0, 0, .25);
  font-family: "M PLUS Rounded 1c";
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  padding: 16px 52px;
  border-radius: 120px;
  margin-top: 42px;
  background: linear-gradient(180deg, #F6B914 0%, #E47E32 100%);
  box-shadow: 0px 96.188px 54px 0px rgba(0, 0, 0, 0.00), 0px 60.75px 54px 0px rgba(0, 0, 0, 0.04), 0px 35.438px 54px 0px rgba(0, 0, 0, 0.12), 0px 15.188px 40.5px 0px rgba(0, 0, 0, 0.20), 0px 5.063px 27px 0px rgba(0, 0, 0, 0.24), 0px 0px 0px 0px rgba(0, 0, 0, 0.24);

  p {
    margin: 0;
    padding-left: 12px;
  }
}


@media screen and (max-width: 720px) {

  .lucky-btn-container,
  .lucky-btn-continue {
    font-size: 20px;
    background-size: 48vw 12vh;
    width: 48vw;
  }

  .number-item {
    font-size: 22px;
  }

  .gift-right-container,
  .gift-left-container {
    display: none;
  }

  .lucky-drawer-container {
    background-size: 90vw 20vh;
    width: 90vw;
    height: 20vh;
  }

  .logo-vita {
    width: 36vw;
  }

  .gift-container {
    width: 80%;

    & .gift-left-item {
      & .name {
        font-size: 16px;
        font-weight: 700;
        line-height: 2%;
      }

      & .price {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .header-top {
    & img {
      height: 72px;
      width: auto;
    }

    & .desc-right {
      & .store-name {
        font-size: 28px;
      }

      & .store-address {
        font-size: 14px;
      }

      & .store-date {
        font-size: 14px;
      }
    }
  }

}
@media screen and (max-width: 2200px) {
  .box-container {
    padding: 3rem 0;
    

    .gift-container {
      padding-bottom: 30px;
      .name {
        font-size: 24px;
      }

      .remaining-prizes {
        font-size: 13px;
      }

      .price {
        font-size: 16px;
      }
    }

    .box-wheel {

      .box-header {
        font-size: 20px;
        max-width: 340px;
      }
    }

    .lucky-btn-container {
      font-size: 32px;
      margin-top: 24px;
    }

    .box-wheel {
      padding-top: 60px;
      padding-bottom: 30px;
    }
  }
}

.modal-dont-start {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000070;
  display: flex;
  align-items: center;
  justify-content: center;

  & .dont-start-container {
    background: white;
    border-radius: 12px;
    padding: 48px;
    max-width: 375px;
    width: 100%;

    & .action-list {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    & .title {
      color: #344054;
      font-size: 18px;
      font-weight: 600;
    }

    & .desc {
      color: #344054;
      font-size: 16px;
      font-weight: 400;
    }

    & .title-end-game {
      color: #344054;
      font-size: 26px;
      font-weight: 700;
    }

    & .desc-end-game {
      font-size: 18px;
    }

    & .btn-accept {
      background-color: #0054A5;
      color: white;
      width: 100%;
      padding: 16px 0;
      outline: none;
      border: none;
      border-radius: 16px;
      cursor: pointer;
      margin-top: 16px;
    }
  }
}