@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');

body {
  margin: 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: "M PLUS Rounded 1c", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}